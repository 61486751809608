import { atom, selector } from "recoil";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import isApiErrorResponse from "smavesto.core/lib/utils/typeguards/isApiErrorResponse";
import { loggedInUserUriState } from "./auth.state";
import { currentDepotState } from "./current.depot.state";
import { getRequestState, useRefreshState } from "./request.state";

export const currentMassOrderIDState = atom({
  key: "currentMassOrderID",
  default: selector({
    key: "currentMassOrderID/Default",

    get: ({ get }) => {
      const [massOrder] = get(massOrdersState);

      return massOrder?.id.toString() ?? "";
    }
  })
});

export const directDepositsState = selector({
  key: "directDeposits",
  get: async ({ get }) => {
    const userUri = get(currentDepotState)?.userUri;

    get(getRequestState("directDeposits", userUri));

    if (!userUri) return [];

    const response = await smavestoCore.services.simulate.lastschriften(
      userUri
    );

    if (isApiErrorResponse(response)) return [];

    return response;
  }
});

export const useRefreshDirectDeposits = (environment: StateEnvironmentUser) =>
  useRefreshState("directDeposits", environment);

export const currentDirectDepositIDState = atom({
  key: "currentDirectDepositID",
  default: selector({
    key: "currentDirectDepositID/Default",

    get: ({ get }) => {
      const [directDeposit] = get(directDepositsState);

      return directDeposit?.lastschriftId.toString() ?? "";
    }
  })
});

export const massOrdersState = selector({
  key: "massOrders",
  get: async ({ get }) => {
    const userUri = get(loggedInUserUriState);
    get(getRequestState("massOrdersRequest", userUri));

    if (!userUri) return [];

    const response = await smavestoCore.services.simulate.massOrders(userUri);

    if (isApiErrorResponse(response)) return [];

    return response;
  }
});

export const simulatePrivacyUpgradeRequiredState = atom<boolean>({
  key: "simulatePrivacyUpgradeRequiredState",
  default: false
});

export const simulateFeatureFlagsLikeProductionState = atom<boolean>({
  key: "simulateFeatureFlagsLikeProductionState",
  default: false
});

export const useRefreshMassOrders = (environment: StateEnvironmentUser) =>
  useRefreshState("massOrdersRequest", environment);
