import { atom, selector, selectorFamily } from "recoil";

import type { StateEnvironmentUser } from "@models/types/StateEnvironment";
import { DepotTimeRanges } from "@page-components/dashboard/Depot/TimeRanges";
import { sortDepots } from "@utils/sort/sortDepots";
import isUnderageDepot from "smavesto.core/lib/utils/typeguards/isJuniorDepot";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import onboardingUnderageLabels from "@src/page-components/onboarding-underage/labels/onboardingUnderageLabels";
import onboardingLabels from "@src/page-components/onboarding/investment-settings/labels/onboardingLabels";
import type DepotDto from "smavesto.core/lib/types/dto/depot/DepotDto";
import successOrUndefined from "smavesto.core/lib/utils/processing/successOrUndefined";
import { loggedInUserUriState, userUriState } from "./auth.state";
import { currentDepotState } from "./current.depot.state";
import { onboardingState } from "./onboarding.state";
import { getRequestState, useRefreshState } from "./request.state";

export const rangeDepotIDState = atom<
  | "FROM_BEGIN"
  | "LAST_12_MONTH"
  | "WHOLE_LAST_YEAR"
  | "WHOLE_YEAR"
  | "LAST_7_DAYS"
>({
  key: "rangeDepotID",
  default: "FROM_BEGIN"
});

export const depotsState = selector({
  key: "depots",
  get: async ({ get }) => {
    const userUri = get(loggedInUserUriState);

    if (!userUri) return [];

    get(getRequestState("depots", userUri));

    return userUri
      ? successOrUndefined<DepotDto[]>(
          await smavestoCore.services.user.getDepots(userUri)
        )?.sort(sortDepots) ?? []
      : [];
  }
});
export const useRefreshDepot = (environment: StateEnvironmentUser) =>
  useRefreshState("depot", environment);

export const childDepotsState = selector({
  key: "childDepots",
  get: async ({ get }) => {
    const depots = get(depotsState);

    const childDepots = depots.filter(
      d => d.customerDepotRelationshipType === "Parent"
    );

    return childDepots;
  }
});

export const loggedInUserHasNoOwnDepotsState = selector({
  key: "loggedInUserHasNoOwnDepots",
  get: async ({ get }) => {
    const depots = get(depotsState);

    const ownedDepots = depots.filter(
      d => d.customerDepotRelationshipType === "Owner"
    );

    return ownedDepots.length === 0;
  }
});

export const activeOnboardingState = selector({
  key: "activeOnboarding",
  get: async ({ get }) => {
    const depots = get(depotsState);

    // find incomplete onboarding datas
    const [onboarding] = depots
      .map(depot => get(onboardingState(depot.userUri)))
      .filter(onboardingData => !onboardingData?.onboardingCompleted);

    return onboarding;
  }
});

export const notJuristicDepotState = selector({
  key: "notJuristicDepotState",
  get: async ({ get }) => {
    const depots = get(depotsState);

    get(getRequestState("onboarding"));

    // find incomplete onboardings
    const onboarding = depots.find(depot => !depot.juristic);

    return onboarding;
  }
});

export const activeOnboardingDepotState = selector({
  key: "activeOnboardingDepot",
  get: async ({ get }) => {
    const depots = get(depotsState);

    get(getRequestState("onboarding"));

    // find incomplete onboardings
    const onboarding = depots.find(
      depot => !get(onboardingState(depot.userUri))?.onboardingCompleted
    );

    return onboarding;
  }
});

export const userIsSecondGuardianState = selector<boolean>({
  key: "userIsSecondGuardian",
  get: async ({ get }) => {
    const activeOnboardingDepot = get(activeOnboardingDepotState);

    const userIsSecondGuardian =
      activeOnboardingDepot?.customerToCustomerRelationshipType ===
      "GUARDIAN_2";

    return userIsSecondGuardian;
  }
});

/* This selector returns all child depots of a user */
export const userChildDepotsSelector = selector({
  key: "userChildDepots",
  get: async ({ get }) => {
    const depots = get(depotsState);

    const childDepots = depots.filter(
      item => item.customerDepotRelationshipType === "Parent"
    );

    return childDepots;
  }
});

/* This selector indicates whether there is a depot that is waiting for a second legal representative to verify */
export const isLoggedInAsFirstGuardianOfActiveOnboardingState = selector({
  key: "isWaitingForSecondGuardianToVerifyChildDepot",
  get: async ({ get }) => {
    const activeOnboardingDepot = get(activeOnboardingDepotState);

    return (
      activeOnboardingDepot?.customerToCustomerRelationshipType === "GUARDIAN_1"
    );
  }
});

export const currentDepotIsUnderageState = selector<boolean>({
  key: "currentDepotIsUnderage",
  get: async ({ get }) => {
    const currentDepot = get(currentDepotState);

    if (!currentDepot) return false;

    return isUnderageDepot(currentDepot);
  }
});

export const activeOnboardingIsUnderageState = selector<boolean>({
  key: "activeOnboardingIsUnderageState",
  get: async ({ get }) => {
    const activeOnboardingDepot = get(activeOnboardingDepotState);

    if (!activeOnboardingDepot || !isUnderageDepot(activeOnboardingDepot))
      return false;

    return true;
  }
});

export const onboardingLabelsByActiveOnboardingState = selector<any>({
  key: "onboardingLabelsByActiveOnboardingState",
  get: async ({ get }) => {
    const activeOnboardingIsUnderage = get(activeOnboardingIsUnderageState);

    if (activeOnboardingIsUnderage === true) return onboardingUnderageLabels;

    return onboardingLabels;
  }
});

export const depotDetailsForDepotId = selectorFamily({
  key: "depotDetailsForDepotId",
  get:
    (depotId: string) =>
    async ({ get }) => {
      const depot = get(depotsState).find(depot => depot.id === depotId);

      if (!depot) return undefined;

      const rangeDepotID = get(rangeDepotIDState);

      const foundRange = DepotTimeRanges.find(
        range => range.rangeId === rangeDepotID
      );

      if (!foundRange) return undefined;

      get(getRequestState("depotDetails", depotId));

      return successOrUndefined(
        await smavestoCore.services.depot.getDetails(
          depot.id,
          foundRange.getFrom(depot.openingDate),
          foundRange.getTo()
        )
      );
    }
});

export const depotDetailsState = selector({
  key: "depotDetails",
  get: async ({ get }) => {
    const currentDepot = get(currentDepotState);

    if (!currentDepot) return undefined;

    const rangeDepotID = get(rangeDepotIDState);

    const foundRange = DepotTimeRanges.find(
      range => range.rangeId === rangeDepotID
    );

    if (!foundRange) return undefined;

    get(getRequestState("depotDetails", currentDepot.id));

    return successOrUndefined(
      await smavestoCore.services.depot.getDetails(
        currentDepot.id,
        foundRange.getFrom(currentDepot.openingDate),
        foundRange.getTo()
      )
    );
  }
});

export const depotAllocationsState = selector({
  key: "depotAllocations",
  get: async ({ get }) => {
    const depot = get(currentDepotState);

    if (!depot) return [];

    get(getRequestState("depotAllocations", depot.id));
    return depot
      ? successOrUndefined(
          await smavestoCore.services.depot.getAllocations(depot.id)
        ) ?? []
      : [];
  }
});

export const depotForLoggedInUserState = selector({
  key: "depotForLoggedInUserState",
  get: async ({ get }) => {
    const loggedInUserUri = get(userUriState("loggedInUser"));

    if (!loggedInUserUri) return undefined;

    return get(depotForUserUriState(loggedInUserUri));
  }
});

export const depotForUserUriState = selectorFamily({
  key: "depotForUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      const depots = get(depotsState);

      return depots.find(d => d.userUri === userUri);
    }
});

export const isUnderageUserUriState = selectorFamily({
  key: "isUnderageUserUriState",
  get:
    (userUri: string) =>
    async ({ get }) => {
      const depot = get(depotForUserUriState(userUri));

      return depot ? isUnderageDepot(depot) : false;
    }
});
