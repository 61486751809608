import { CloseOutlined } from "@ant-design/icons";
import { ThemeContext } from "@src/pages/_app";
import {
  activeOnboardingDepotState,
  depotsState
} from "@src/state/depot.state";
import { SButton } from "@src/styled-components/form/SButtons";
import useCloneSupplierForNewDepot from "@src/theme/hook/useCloneSupplierForNewDepot";
import { Col, Modal, Row } from "antd";
import { useContext, useState } from "react";
import { useAsyncSelector } from "../useAsyncSelector";

const useNewDepotModal = () => {
  const { theme, appModeTheme } = useContext(ThemeContext);

  const [selectNewDepotModalOpen, setSelectNewDepotModalOpen] =
    useState<boolean>();

  const [, depots] = useAsyncSelector(depotsState);

  const userHasOwnDepot: boolean =
    (depots &&
      !!depots.find(
        depot => depot.customerDepotRelationshipType === "Owner"
      )) ||
    false;

  const { contextHolder, createNewDepot } = useCloneSupplierForNewDepot();

  const [, activeOnboarding] = useAsyncSelector(activeOnboardingDepotState);

  const isAnyDepotInOnboarding = !!activeOnboarding;

  const multiContextHolder = (
    <>
      {contextHolder}{" "}
      <Modal
        bodyStyle={{
          backgroundColor: appModeTheme?.modalBackgroundColor,
          color: appModeTheme?.secondLayerTextColor
        }}
        footer={null}
        onCancel={() => {
          setSelectNewDepotModalOpen(false);
        }}
        closable
        closeIcon={<CloseOutlined style={{ fill: "white", color: "white" }} />}
        visible={selectNewDepotModalOpen}
        okButtonProps={{
          hidden: true
        }}
        cancelButtonProps={{
          hidden: true
        }}
      >
        <h3 style={{ marginBottom: 20 }}>Welches Depot möchten Sie anlegen?</h3>
        {userHasOwnDepot ? (
          <p>
            Sie können für jedes Ihrer Kinder bei uns ein Junior Depot anlegen.
            {isAnyDepotInOnboarding && (
              <>
                {" "}
                Sollte der Button für Sie nicht anklickbar sein, befindet sich
                eines Ihrer Depots voraussichtlich noch in der Eröffnung.
              </>
            )}{" "}
            Sollten Sie bereits ein eigenes Depot haben, können Sie im Moment
            keine weiteren, für Sie selbst, bei uns eröffnen.
          </p>
        ) : (
          <p>
            Sie haben die Möglichkeit, entweder ein Depot für sich selbst bei
            uns anzulegen oder für jedes Ihrer Kinder ein eigenes Junior Depot
            zu eröffnen.
          </p>
        )}
        <Row style={{ marginTop: 40 }}>
          <Col>
            {!userHasOwnDepot && (
              <SButton
                style={{ marginRight: 30 }}
                disabled={isAnyDepotInOnboarding}
                type="primary"
                htmlType="button"
                className="general"
                onClick={() => createNewDepot("default")}
              >
                Neues eigenes Depot
              </SButton>
            )}
          </Col>
          <Col>
            <SButton
              disabled={isAnyDepotInOnboarding}
              type="primary"
              htmlType="button"
              className="general"
              onClick={() => createNewDepot("underage")}
            >
              Neues Junior Depot
            </SButton>
          </Col>
        </Row>
      </Modal>
    </>
  );

  return { setSelectNewDepotModalOpen, multiContextHolder };
};

export default useNewDepotModal;
