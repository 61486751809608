import { WarningOutlined } from "@ant-design/icons";
import { useRouter } from "next/router";
import { useState } from "react";

import { useAsyncSelector } from "@hooks/useAsyncSelector";
import {
  currentDepotOnboardingState,
  currentDepotUserUriState
} from "@states/current.depot.state";
import { useRefreshOnboarding } from "@states/onboarding.state";
import { SButton } from "@styled-components/form/SButtons";
import theme from "@styles/theme";

import smavestoCore from "@src/utils/services/SmavestoCoreClient";

import { getNextStepToOnboarding } from "../page-container/SecureLayout/onboardingSteps";
import BasicInfoBox from "./BasicInfoBox";

const TestDepotInfoBox: React.FunctionComponent = () => {
  const { push } = useRouter();

  const [, userUri] = useAsyncSelector(currentDepotUserUriState);

  const [, onboarding] = useAsyncSelector(currentDepotOnboardingState);

  const refreshOnboarding = useRefreshOnboarding("loggedInUser");

  const [isLoading, setIsLoading] = useState(false);

  const onGoToOnboarding = async () => {
    if (!onboarding || !userUri) return;

    setIsLoading(true);

    await smavestoCore.services.onboarding.setDirectAccess(userUri, true);
    await smavestoCore.services.onboarding.setGoToOnboarding(userUri, true);

    refreshOnboarding();

    !onboarding.onboardingCompleted &&
      push(getNextStepToOnboarding(onboarding.completedChapters));

    setIsLoading(false);
  };

  return (
    <BasicInfoBox
      title="Weiter zum Onboarding"
      icon={<WarningOutlined style={{ fontSize: 36, color: theme.primary }} />}
      text="Smavesto ist die Geldanlage der Zukunft: Intelligent, smart und sicher. Als verlässlicher 
      Navigator sorgt er für den richtigen Kurs."
      actions={[
        <div key={0}>
          Ich möchte weiter onboarden und direkt im Live-Depot anlegen.
        </div>,
        <SButton
          key={1}
          type="primary"
          style={{ margin: "auto" }}
          onClick={onGoToOnboarding}
          disabled={isLoading}
          loading={isLoading}
        >
          Weiter zum Onboarding
        </SButton>
      ]}
    />
  );
};

export default TestDepotInfoBox;
