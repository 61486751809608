import { Col } from "antd";
import type { FC } from "react";

import type { ICompBaseProps } from "@src/interfaces/jsx.interface";
import AppStoreBadge from "../../../../assets/App_Store_Badge_DE.svg";

import { SFooter } from "./styled";

export interface IFooterLinks {
  id: number;
  name: string;
  route: string;
}

interface IProps extends ICompBaseProps {
  links: IFooterLinks[];
  isSecure?: boolean;
}

export const Footer: FC<IProps> = ({ links, isSecure }) => (
  <SFooter>
    <Col xs={24} sm={7} md={5} lg={5} xl={4} className="logo" />
    <Col xs={24} sm={17} md={19} lg={12} xl={14} className="links">
      {links.map(i => (
        <a
          key={i.id}
          href={`https://www.smavesto.de/${i.route}/`}
          rel="noreferrer"
          target="_blank"
        >
          {i.name}
        </a>
      ))}
    </Col>

    <Col className="stores">
      {isSecure && (
        <>
          <a
            href="https://apps.apple.com/de/app/smavesto/id1441091680"
            target="_blank"
            rel="noreferrer"
          >
            <AppStoreBadge className="app-store-badge" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.smavesto&hl=en_US&gl=US"
            target="_blank"
            rel="noreferrer"
          >
            <div className="google-play-badge" />
          </a>
        </>
      )}
    </Col>
  </SFooter>
);
