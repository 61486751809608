import type { Moment } from "moment";
import moment from "moment";

type FEATURE_FLAG_DICTIONARY = {
  feature: FEATURE_FLAG_FEATURE;
  available: FEATURE_FLAG_AVAILABILITY;
  activateOnDate?: Moment;
};
type FEATURE_FLAG_AVAILABILITY =
  | "development" // webapp-dev.smavesto.de
  | "production" // app.smavesto.de
  | "release-on" // Release on a specific date
  | "none"; // not available

export type FEATURE_FLAG_FEATURE =
  | "JUNIOR_DEPOT_DASHBOARD"
  | "JUNIOR_DEPOT_DOCUMENTS"
  | "JUNIOR_DEPOT_TRANSACTIONS"
  | "CREATE_JUNIOR_DEPOT"
  | "CUSTOM_THEMES"
  | "LEGITIMATION_AT_SPARKASSE"
  | "NEW_SIEGEL"
  | "UPPR"
  | "NEWS"
  | "PRISMIC"
  | "READ_DOCUMENTS"
  | "SOCCER_LOGO"
  | "FSA_2FA";

const FEATURE_FLAGS: FEATURE_FLAG_DICTIONARY[] = [
  { feature: "JUNIOR_DEPOT_DASHBOARD", available: "production" },
  { feature: "PRISMIC", available: "production" },
  { feature: "JUNIOR_DEPOT_DOCUMENTS", available: "production" },
  { feature: "JUNIOR_DEPOT_TRANSACTIONS", available: "production" },
  { feature: "CREATE_JUNIOR_DEPOT", available: "production" },
  {
    feature: "CUSTOM_THEMES",
    available: "release-on",
    activateOnDate: moment(new Date("2023-03-14T00:00:00"))
  },
  { feature: "LEGITIMATION_AT_SPARKASSE", available: "production" },
  {
    feature: "NEW_SIEGEL",
    available: "release-on",
    activateOnDate: moment(new Date("2023-11-07T00:00:00"))
  },
  {
    feature: "UPPR",
    available: "production"
  },
  {
    feature: "NEWS",
    available: "production"
  },
  { feature: "READ_DOCUMENTS", available: "production" },
  { feature: "FSA_2FA", available: "development" },
  { feature: "SOCCER_LOGO", available: "development" }
];

export default function isFeatureFlagged(
  key: FEATURE_FLAG_FEATURE,
  simulateProduction?: boolean
) {
  const USE_DEBUG = simulateProduction
    ? false
    : process.env.NEXT_PUBLIC_USE_DEBUG;

  const feature = FEATURE_FLAGS.find(ff => ff.feature === key);
  const ignoreDevEnvironment = false;

  // Feature is not available
  if (feature?.available === "none") return true;

  // Release this feature on a specific date
  if (feature?.available === "release-on") {
    if (USE_DEBUG) return false;

    return feature?.activateOnDate?.isAfter(moment(new Date()));
  }

  // Feature is only available on development
  if (
    feature?.available === "development" &&
    (USE_DEBUG !== "true" || ignoreDevEnvironment)
  )
    return true;

  return false;
}
